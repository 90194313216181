import { useCallback, useEffect, useState } from "react";
import { Particles } from "react-particles";
import { loadSlim } from "tsparticles-slim"; // Continue using loadSlim as you are already doing
import styled from "@emotion/styled";
 
export const HotDogAlertParticles = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const options = {
    // background: {
    //   color: {
    //     value: "#000000",
    //   },
    // },
    fpsLimit: 30,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "repulse",
          distance: 200,
        },
        resize: true,
      },
    },
    particles: {
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 6,
        straight: false,
      },
      rotate: {
        value: 0,
        random: true,
        direction: "clockwise",
        animation: {
          enable: true,
          speed: 2,
          sync: false,
        },
      },
      number: {
        value: 30,
      },
      opacity: {
        value: 1,
      },
      shape: {
        type: "character",
        character: {
          value: "🌭",
          font: "Verdana",
          style: "",
          weight: "",
        },
      },
      size: {
        value: { min: 20, max: 50 },
      },
    },
    detectRetina: true,
  };

  return (
    // <BackgroundWrapper>
      <StyledParticles
        id="tsparticles"
        init={particlesInit}
        options={options}
      />
    // </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -3;
  animation: backgroundColorChange 1s infinite alternate;

  @keyframes backgroundColorChange {
    0% {
      background-color: #000000;
    }
    100% {
      background-color: #990000;
    }
  }
`;

const StyledParticles = styled(Particles)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -3;
`;
