import styled from "@emotion/styled";
// import Particles from "@tsparticles/react";
import Particles from "react-particles";

import { useCallback } from "react";
import { loadHyperspacePreset } from "tsparticles-preset-hyperspace";


export function HyperSpace(props) {
  const customInit = useCallback(async (engine) => {
    await loadHyperspacePreset(engine);
  });

  const options = {
    preset: "hyperspace",
    interactivity: {
      events: {
        // onClick: {
        //   enable: true,
        //   mode: "push",
        // },
        onHover: {
          enable: true,
          mode: ["attract", "repulse"],
          distance: 200,
        },
        modes: {
        
          attract: {
            distance: 1,
            duration: 0.1,
          },
        },
        resize: true,
      }
    },
    particles: {
      color: {
        value: "#BBFFFF",
      },
      size: {
        value: 3
      }
    }
  };

  return <StyledParticles options={options} init={customInit} />;
}

const StyledParticles = styled(Particles)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
